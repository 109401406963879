const data = {
  home: {
    title: "Hi, I'm theTechBoi",
    subtitle: "Agile Software Engineer",
    description:
    "Meet a Global Talent: A problem-solver who believes in solutions, finds relief in challenges, and passionate about learning and continuous growth."      
    // "A Global talent, Who takes pride in playing with different stacks and love to give breath to Ui/Ux designs.",
  },
  about: {
    description:
      "A self-motivated and enthusiatic Software Engineer who is passionate about writing clean and structured code. I am also committed to both personal and professional growth learning from any situation in which i find myself. As long as i collaborate with people who build on my interest in the field of technology, I can overcome every hurdle. Cheers! you now know about me.",
  },
  services: {
    web: [
      {
        id: 1,
        desc: "Javascript/TypeScript/Reactjs",
      },
      {
        id: 2,
        desc: "HTML5/CSS/Tailwind CSS/Styled-Components/Material UI",
      },
      {
        id: 3,
        desc: "Version control; Git, GitHub, BitBucket",
      },
      {
        id: 4,
        desc: "Designing beautiful mobile first website",
      },
      {
        id: 5,
        desc: "Writing high-quality code",
      },
    ],
    mobile: [
      {
        id: 1,
        desc: "Proficient in React Native, which is commonly used for developing mobile apps accross all devices.",
      },
      {
        id: 2,
        desc: "HTML5/CSS/Tailwind CSS/Styled-Components/Material UI",
      },
      {
        id: 3,
        desc: "Version control; Git, GitHub, BitBucket",
      },
      {
        id: 4,
        desc: "Deployment: Azure, Netlify, Vercel, Git page, Heroku ",
      },
      {
        id: 5,
        desc: "Writing high-quality code",
      },
      {
        id: 6,
        desc: "Agile methodologies such as Scrum and Kanban, which are commonly used in mobile development to manage the life cycle",
      },
    ],
    testing: [
      {
        id: 1,
        desc: "Choose the right automation tool that supports testing for both website and mobile app.",
      },
      {
        id: 2,
        desc: "Define the scope and objectives of the automation testing, features to be tested, test scenarios, and the expected outcomes.",
      },
      {
        id: 3,
        desc: "Develop test cases that cover all the functionalities.",
      },
      {
        id: 4,
        desc: "Prepare the test environment by installing the necessary software, configuring the test devices and browsers.",
      },
      {
        id: 5,
        desc: "Create test scripts that automate the test cases, and incorporate them into a test suite.",
      },
      {
        id: 6,
        desc: "Run the test suite to perform automated testing, and verify that the test cases pass or fail as expected.",
      },
      {
        id: 7,
        desc: "Analyze and report the results to identify any issues, and generate a report that summarizes the findings.",
      },
      {
        id: 8,
        desc: "Debug and retest the affected test cases to ensure that they pass.",
      },
      {
        id: 9,
        desc: "Maintain and update the test suite",
      },
    ],
    support: [
      {
        id: 1,
        desc: "Your web or mobile app is always growing. Whether you’re adding new features or making improvements I’m here to help. I won’t leave you guessing how your own application works and will always be available to answer any questions you might have.",
      }]
  },
  experience: {
    Companies: [
      {
        id: 1,
        desc: `Collaborating with product managers, OA team, UI/UX designers
        and backend Developers in developing and shipping various features
        on both web applications and mobile applications.`,
      },
      {
        id: 2,
        desc: `My role requires building stable and maintainable codebase using
        ReactJs and modern technologies.
        - I collaborate with the Agile team to ensure products were efficient, giving users a seamless experience, likewise Working directly with client stakeholders to develop technical
        solutions for business cases.
        - Revamped the current company website and fixed some edged cases that were leading to revenue loss.  
        - Implemented the new application to mobilize the VIP guest, a partnership application with the company where partners can signup and on-board their vehicles for guests to hire
        - I started and completed the VIP hires admin dashboard and implemented all the required securities.
        - Revamping the company mobility dashboard
        - Also, I single-handedly built the GMD official website. The website showcase all the groups and subsidiaries owned by the GMD and more.
        - Bringing a concept to life with HTML, CSS, Javascript, and React
        - Production and maintenance of websites and web application user interfaces
        - Building reusable components and custom hooks for future use.
        - Maintaining software workflow management
        - Testing the site for usability and fixing any bugs`,
      },
      {
        id: 3,
        desc: `My role is to write and style the front-end components that meet the requirements of our mock-ups and fulfill our user stories. 
        - I also monitor and process pull requests for production deployments. Technologies used: ReactJs, JavaScript, ES6, TailwindCSS, CSS3, HTML5, Styled-Components. 
        - Designed and developed responsive and maintainable web applications according to Figma design, business objectives, and needs
        - Manage development milestones from initial steps through the final delivery
        - Determining the structure and design of web pages.
        - Improving website SEO
        - Ensuring user experience determines design choices.
        - Developing features to enhance the user experience.
        - Ensuring web design is optimized for smartphones`,
      },
      {
        id: 4,
        desc: `Translated designs and wireframes into high-quality code and wrote application interface code via javaScript following React.js workflows.
        - Designed and developed efficient and maintainable web applications according to business objectives and needs
        - Developed client website applications using HTM, CSS, Bootstrap, and JavaScript
        - Working with GitHub
        - Ensuring user experience determines design choices.
        - Developing features to enhance the user experience.`,
      },
    ],
  },

  projects: [
    {
      id: 1,
      img: "/assets/img/finch-agent.PNG",
      demoLink: "https://finch-agent-dashboard.k8.isw.la/agent/login",
      // demoLink: "https://paypoint.quickteller.com/",
      title: "Quickteller Paypoint Agent Portal",
      description:
        "Quickteller Paypoint is a robust consumer services platform for convenient Airtime Recharge, Funds Transfer, Bill Payments, Cash Deposits, Cash Withdrawals and Insurance.",
    },
    {
      id: 2,
      img: "/assets/img/gigm.PNG",
      demoLink: "https://gigm.com/",
      title: "GIGM Official Website - Revamped",
      description:
        "The official web application of GIG Mobility. This application is very simple and easy to use. You can book your bus travel ticket without boundaries. Accessible anytime anywhere. You can also track all your booking activities and get up to date information from the web application.",
    },
    {
      id: 3,
      img: "/assets/img/finch-admin.PNG",
      demoLink: "https://finch-admin-dashboard.k8.isw.la/admin/login",
      title: "Quickteller Paypoint Admin Portal",
      description: `It is the offical back office to manage and controls all on-boarded agent requests and applications.
        It was implemented on the Applications Management Portal that
        houses all Quickteller Paypoint customer applications and requests.
        `,
    },
    {
      id: 4,
      img: "/assets/img/e-commerce.PNG",
      demoLink: "https://e-commerce-shopping-app-context.netlify.app/",
      title: "Online Shopping E-commerce Website",
      description:
        "A React Ecommerce website designed to revolutionize your shopping experience. With a wide range of products available at your fingertips.",
    },
    {
      id: 5,
      img: "/assets/img/viphire-partner.PNG",
      demoLink: "https://viphire-partner-web.netlify.app/",
      title: "GIGM VIP-Hire Web Application [Partners]",
      description:
        "An application to mobilize the VIP guest, a partnership application with the company, where partners can signup and on-board their vehicles for guests to hire",
    },

    {
      id: 6,
      img: "/assets/img/viphire-admin.PNG",
      demoLink: "https://gigm-vip-hire-admin.netlify.app/",
      title: "GIGM VIP-Hire Web Application [Admin]",
      description:
        "A React application built to coordinate all the activities of the on-boarded partners and control the guest requests.",
    },
    {
      id: 7,
      img: "/assets/img/food-recipe.PNG",
      demoLink: "https://react-recipe-ingredient-app.netlify.app/",
      title: "Food Recipe Website",
      description:
        "A React built to Analyze any recipe or ingredient list Automatic tagging for allergens and popular diets Database of close to 900,000 food items. 2.3+ million nutritionally analyzed recipes Detailed macro and micronutrient data Filter by calories, diets and allergens",
    },
    {
      id: 8,
      img: "/assets/img/zikoko.PNG",
      demoLink: "https://zikoko-website-cloned.netlify.app/",
      title: "Zikoko Official Website - Cloned",
      description:
        "A cloned Zikoko official website",
    },
    {
      id: 9,
      img: "/assets/img/calculator.PNG",
      demoLink: "https://calculator-madewith-glassmorphism.netlify.app/",
      title: "A Functional Glassmorphism Calculator",
      description:
        "A React functional glasssmorphism versatile tool that offers a range of essential functionalities to simplify numerical computations. It serves as a reliable aid in various settings, from basic arithmetic calculations to complex mathematical operations.",
    },
    {
      id: 10,
      img: "/assets/img/creditclan-website.PNG",
      demoLink: "https://creditclan-website-cloned.netlify.app/",
      title: "Creitclan Official Website - Cloned",
      description:
        "A cloned CreditCLan Official Website",
    },
    {
      id: 11,
      img: "/assets/img/table-chart.PNG",
      demoLink: "https://react-data-table-chart-analysis-dashboard.netlify.app/",
      title: "A Data Chart Analysis Dashboard",
      description:
        "A React Data Chart Analysis comprehensive Dashboard tool designed to provide insights and facilitate data-driven decision-making. It offers a range of functionalities to analyze and visualize data effectively. ",
    },
    {
      id: 12,
      img: "/assets/img/tailwind-landing-page.PNG",
      demoLink: "https://landingpage-created-with-tailwindcs.netlify.app/",
      title: "Manage Landing Page",
      description:
        "A React Landing page deigned using a range of modern libraries like TailwindCSS and React.js, managing the states using ContextAPI",
    },
    {
      id: 13,
      img: "/assets/img/tailwind-typing-effect.PNG",
      demoLink: "https://tailwind-website-with-typing-effect.netlify.app/",
      title: "Landing Page With Typing Effect",
      description:
        "A React landing page built and designed with TailwindCSS",
    },
    {
      id: 14,
      img: "/assets/img/password-generator.PNG",
      demoLink: "https://autogenerate-password.netlify.app/",
      title: "Password Generator App",
      description:
        "A password generator application tool designed to create strong and secure passwords for various accounts and online services.",
    },
    {
      id: 6,
      img: "/assets/img/thegreatman.PNG",
      demoLink: "https://thegreatmanlegend1-0.netlify.app/",
      title: "TheGreatMan Legent Official Website",
      description:
        "An official website of the GMD and Executive Director of God Is Good Mobility companies",
    },
  ],
  contact: {
    phone: "+234 70 110 88799",
    email: "theTechBoi.nugagee@gmail.com",
    location: "Lagos, Nigeria",
  },
};

export const socialLinks = {
  github: "https://github.com/nugagee",
  twitter: "https://twitter.com/nugagee",
  linkedIn: "https://www.linkedin.com/in/adenuga-adewale-thetechboi-nugagee-289401102/",
};

export default data;
