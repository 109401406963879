import { UilDownloadAlt } from "@iconscout/react-unicons";
import React from "react";
import { Helmet } from "react-helmet-async";
import "./About.css";
import AboutInfo from "./AboutInfo";

function About({ about }) {
  const { description } = about;

  return (
    <>
      <Helmet>
        <title>Adenuga Olajide - Software Engineer</title>
        <meta
          name="description"
          content="Have you seen for a self-motivated and enthusiatic Software Engineer who is passionate about writing clean and structured code? No?"
        />
        <link rel="canonical" href="/#about" />
      </Helmet>
      <section className="about section" id="about">
        <h2 className="section__title">About me</h2>
        <span className="section__subtitle">My Introduction</span>
        <div className="about__container container grid">
          <img src="/assets/img/about.png" alt="" className="about__img" />

          <div className="about__data">
            <p className="about__description">{description}</p>

            <div className="about__info">
              <AboutInfo title="4+" name="Years experience" />
              <AboutInfo title="15+" name="Completed projects" />
              <AboutInfo title="4" name="Companies worked" />
            </div>

            <div className="about__buttons">
              <a
                href="https://drive.google.com/file/d/1HUctUyY-Al5nHgnXRvIosHXTklx6TUiG/view?usp=share_link"
                className="button button--flex"
                style={{marginRight: "10px"}}
                target="_blank"
                rel="noreferrer" download
              >
                Download CV
                <UilDownloadAlt />
              </a>
              <a
                href="https://drive.google.com/file/d/1S63ZlP2lSzZ408-NdJoMPXVKuVgYs95u/view?usp=share_link"
                className="button button--flex"
                target="_blank"
                rel="noreferrer" download
              >
                Cover Letter
                <UilDownloadAlt />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
